import(/* webpackMode: "eager", webpackExports: ["ClientScripts"] */ "/vercel/path0/apps/vercel-docs/app/components/client-scripts.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["RootHooks"] */ "/vercel/path0/apps/vercel-docs/app/components/root-hooks.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SwitcherProvider"] */ "/vercel/path0/apps/vercel-docs/app/components/switchers/context.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/vercel-docs/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["Analytics"] */ "/vercel/path0/node_modules/.pnpm/@vercel+analytics@1.3.1_next@15.0.0-canary.158_@babel+core@7.24.5_@opentelemetry+api@1.7.0_@p_u3iaswz6kkij2ugnobwlbax4z4/node_modules/@vercel/analytics/dist/next/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["SpeedInsights"] */ "/vercel/path0/node_modules/.pnpm/@vercel+speed-insights@1.0.11_next@15.0.0-canary.158_@babel+core@7.24.5_@opentelemetry+api@1._qwutgp6qy5az6ideckki7fs534/node_modules/@vercel/speed-insights/dist/next/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.0.0-canary.158_@babel+core@7.24.5_@opentelemetry+api@1.7.0_@playwright+test@1.44.1_ba_22vhrroyeiaz63rx3fj7ykwjju/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.0.0-canary.158_@babel+core@7.24.5_@opentelemetry+api@1.7.0_@playwright+test@1.44.1_ba_22vhrroyeiaz63rx3fj7ykwjju/node_modules/next/font/local/target.css?{\"path\":\"../../packages/geist/src/fonts/index.ts\",\"import\":\"\",\"arguments\":[{\"display\":\"swap\",\"variable\":\"--font-sans\",\"fallback\":[\"Apple Color Emoji\",\"Segoe UI Emoji\",\"Segoe UI Symbol\",\"arial\"],\"adjustFontFallback\":false,\"src\":\"./GeistVF.woff2\"}],\"variableName\":\"geistSansFont\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.0.0-canary.158_@babel+core@7.24.5_@opentelemetry+api@1.7.0_@playwright+test@1.44.1_ba_22vhrroyeiaz63rx3fj7ykwjju/node_modules/next/font/local/target.css?{\"path\":\"../../packages/geist/src/fonts/index.ts\",\"import\":\"\",\"arguments\":[{\"display\":\"block\",\"variable\":\"--font-mono\",\"src\":\"./GeistMonoVF.woff2\",\"adjustFontFallback\":false,\"fallback\":[\"ui-monospace\",\"Apple Color Emoji\",\"Segoe UI Emoji\",\"Segoe UI Symbol\",\"SFMono-Regular\",\"Roboto Mono\",\"Menlo\",\"Monaco\",\"Liberation Mono\",\"DejaVu Sans Mono\",\"Courier New\",\"monospace\"]}],\"variableName\":\"geistMonoFont\"}");
;
import(/* webpackMode: "eager", webpackExports: ["CompatRouterAllowed"] */ "/vercel/path0/packages/compat-router/src/allowed.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["InpMonitor"] */ "/vercel/path0/packages/components/src/inp-monitor/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["GeistProvider"] */ "/vercel/path0/packages/geist/src/core/provider/geist-provider.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/geist/src/styles/index.css");
;
import(/* webpackMode: "eager", webpackExports: ["PrefetchContext","PrefetchProvider"] */ "/vercel/path0/packages/multi-zone/src/prefetch/context.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PrefetchCrossZoneLinks"] */ "/vercel/path0/packages/multi-zone/src/prefetch/prefetch-cross-zone-links.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TelemetryClient"] */ "/vercel/path0/packages/otel/src/next/client.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PathBasedKoalaPopoverCTA"] */ "/vercel/path0/packages/vercel-growth/src/koala-path-popover/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ZoneIndicatorClient"] */ "/vercel/path0/packages/vercel-header/src/zone-indicator/client.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LogoutScreen"] */ "/vercel/path0/packages/vercel-iam/src/logout/with-redirect.tsx");
